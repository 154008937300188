// lists all of the coming events that will be passed as a prop.
<template>
    <div class='fluid-container container main_list_container generic_floating_container'
     v-show="finished_loading" >
      <div class="list_container">
        <Activity :key=event.productId v-for="event in events"
         :event="event"/>

      </div>
    </div>
</template>
<script>
import Activity from '../components/Event.vue'
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'EventList',
  components: {
    Activity,
  },
  props: {
    event_lists: {
      type: Array
    }
  },
  methods: {
    ...mapActions(['getEventList', 'finished_loading','changeDisplayMainHeader', 'resetBookingInfo', 'verifyAccessRequestHash', 'clearCollaboratorData'])
    ,ScheduleEvent(event_id, property_id){
      // here we send the user to the booking component with
      // selected id and everything. in this component I need to implement
      // vuex
    },
    verifyAccessRequestCollaborator(){
      if (this.$route.params.employee_id && this.$route.params.hash_id) {
        const access_request = {'employee_code': this.$route.params.employee_id, 'hash': this.$route.params.hash_id, 'property_token': this.$route.params.prop_id };
        this.$store.dispatch('verifyAccessRequestHash', access_request).then((response) => {console.log(response)}).catch((error) => {
          this.$store.dispatch('changeDisplayLoader', false);
          window.location.replace(process.env.VUE_APP_GH_DIGITAL_URL);
        });
      } else {
        // Clear collaborator data
        this.$store.dispatch('clearCollaboratorData');
      }
    },
  },
  mounted() {
    this.$store.dispatch('resetBookingInfo')
  },
  created(){
    var property_id = this.$route.params.prop_id;
    this.$store.dispatch('changeDisplayMainHeader', true)
    this.$store.dispatch('getEventList', property_id).then((response) => {
      this.verifyAccessRequestCollaborator();
    });
    // this.getEventList(property_id);

  },
  computed:mapGetters(['events']),
  // async created () {
  //   console.log(this.route)
  //   this.getEventList().then(
  //     (response_data)=>{
  //       this.event_list = response_data.available_events.data;
  //       this.booker_property = response_data.property;
  //       // console.log(this.event_list)
  //     }
  //   ).catch((error_data)=>{
  //     console.log(error_data)

  //   })
  // }
}
</script>

<style scoped>
.list_container{
	/* margin: 10px 3% 5% 3%; */
	padding: 15px 15px 15px 15px;
	background-color: #f7f7f7;
}
[v-cloak]{
  display:none;
}
/* @media screen and (min-width:700px) {
  .main_list_container{
    max-width:67.5%;
  }
} */

</style>
